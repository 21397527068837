<template>
    <div>
        <CustomerOverviewSDP
            v-if="showSdpPopup"
            :selected-address="selectedAddressForSdpPopup"
            @close="toggleSdpPopup"
        />
        <div class="asics-table-container clickable-rows overflow-auto">
            <div class="asics-table-toolbar">
                <div class="asics-table-toolbar-item">
                    <select
                        id="items-per-page"
                        v-model="perPage"
                        class="page-item-count"
                        @change="onChangePage(1)"
                    >
                        <option
                            v-for="option in pageSizeOptions"
                            :key="option"
                            :value="option"
                        >
                            {{ option }}
                        </option>
                    </select>
                    <label for="items-per-page">
                        {{ $t('general.labelItemsPerPage') }}
                    </label>
                </div>
                <div class="asics-table-toolbar-item">
                    <SearchInput
                        id="address-search"
                        v-model="searchQuery"
                        :placeholder="$t('general.labelSearch')"
                        @input="onFilterSet"
                    />
                </div>
            </div>
            <Vuetable
                ref="vuetable"
                :api-mode="apiMode"
                :per-page="perPage"
                :fields="fields"
                :data-manager="dataManager"
                track-by="addressNo"
                :show-sort-icons="true"
                :css="css.table"
                :sort-order="sortOrder"
                :no-data-template="noDataMessage"
                :row-class="onRowClass"
                pagination-path="pagination"
                @vuetable:row-clicked="selectRow"
                @vuetable:pagination-data="onPaginationData"
            >
                <template #dcIcon="props">
                    <span class="inline-block">
                        <i
                            v-if="props.rowData.accountGroup === accountGroupDC"
                            class="fa fa-truck"
                            :title="$t('general.tooltipDistributionCenter')"
                        />
                    </span>
                </template>
                <template #defaultDeliveryAddress="{rowData}">
                    <Checkbox
                        :id="`default-delivery-address-${rowData}`"
                        :value="rowData.no === defaultDeliveryAddressNo"
                        :large="true"
                        @change="markAsDefaultShippingAddress(rowData)"
                    />
                </template>
                <template #actions="props">
                    <div class="table-action-buttons">
                        <ButtonPlain
                            :text="$t('general.addressScoring')"
                            @click.native="onShowScoring(props.rowData)"
                        >
                            <i class="fa fa-star" />
                        </ButtonPlain>
                    </div>
                </template>
            </Vuetable>

            <div class="asics-pagination-container">
                <VuetablePaginationInfo
                    ref="paginationInfo"
                    :no-data-template="''"
                    :info-template="$t('general.paginationInfo')"
                />
                <VuetablePagination
                    ref="pagination"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onChangePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import Checkbox from '@/components/checkbox/Checkbox.vue';
import asicsTableMixin from '@/components/data-tables/data-table.mixin.js';
import SearchInput from '@/components/search-input/SearchInput.vue';
import { SimpleSearch } from '@/components/search/fuzzy-search.js';
import { bugsnag } from '@/libs/bugsnag.js';
import { markAsDefaultDeliveryAddress } from '@/modules/admin/customers/customer/customer.api.js';
import CustomerOverviewSDP from '@/modules/admin/customers/customer/CustomerOverviewSDP.vue';
import { getCustomerAddresses } from '@/modules/customer/customer.api.js';
import { Const } from '@/utils/constants.js';
import { Vuetable, VuetablePagination, VuetablePaginationInfo } from 'vuetable-2';

const fuzzyCompareFunc = new SimpleSearch();

export default {
    name: 'AddressList',

    components: {
        Checkbox,
        SearchInput,
        CustomerOverviewSDP,
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        ButtonPlain,
    },

    mixins: [asicsTableMixin],

    props: {
        hidden: {
            type: Array,
            default() {
                return [];
            },
        },

        customerNo: {
            type: String,
            required: true,
        },

        defaultPerPage: {
            type: Number,
            default: 25,
        },
    },

    emits: [
        'addressesLoaded',
        'addressSelected',
    ],

    data() {
        return {
            sortOrder: [
                {
                    field: 'no',
                    direction: 'desc',
                },
            ],
            fields: [
                {
                    name: '__slot:dcIcon',
                    title: '',
                },
                {
                    name: 'no',
                    title: () => this.$t('general.labelAddressNo'),
                    sortField: 'no',
                },
                {
                    name: 'customerNo',
                    title: () => this.$t('general.labelCustomerNo'),
                    sortField: 'customerNo',
                },
                {
                    name: 'name',
                    title: () => this.$t('general.labelAddressName'),
                    sortField: 'name',
                },
                {
                    name: 'address1',
                    title: () => this.$t('general.labelStreet'),
                    sortField: 'address1',
                },
                {
                    name: 'address2',
                    title: () => this.$t('general.labelStreetAdd'),
                    sortField: 'address2',
                },
                {
                    name: 'postCode',
                    title: () => this.$t('general.labelPostCode'),
                    sortField: 'postCode',
                },
                {
                    name: 'city',
                    title: () => this.$t('general.labelCity'),
                    sortField: 'city',
                },
                {
                    name: 'mainDivisionName',
                    title: () => this.$t('general.labelState'),
                    sortField: 'mainDivisionName',
                },
                {
                    name: 'countryCode',
                    title: () => this.$t('general.labelCountryCode'),
                    sortField: 'countryCode',
                },
                {
                    name: '__slot:defaultDeliveryAddress',
                    title: () => this.$t('general.defaultDeliveryAddress'),
                },
                {
                    name: '__slot:actions',
                    title: () => this.$t('general.tableActions'),
                },
            ].filter(Boolean),
            defaultDeliveryAddressNo: null,
            selectedRow: null,
            selectedAddressForSdpPopup: {},
            showSdpPopup: false,
            accountGroupDC: Const.ACCOUNT_GROUP_DC,
            tableCacheName: `table-addresses-${this.customerNo}`,
            perPage: this.defaultPerPage,
        };
    },

    computed: {
        isExternalUser() {
            return this.$store.getters.getUserType === Const.ACCOUNT_TYPE_CUSTOMER;
        },
    },

    watch: {
        customerNo() {
            this.loadData();
        },
    },

    created() {
        if (!this.isExternalUser) {
            this.fields.splice(-2, 1); // Default delivery address feature should not be available for internal users
        }

        this.loadData();
    },

    mounted() {
        if (!this.hidden) {
            return;
        }

        const canSeeAddressScoring = this.$store.getters.getUserCan(Const.PERMISSIONS.CAN_SEE_ADDRESS_SCORINGS);

        this.fields.forEach(field => {
            if (this.hidden.includes(field.name)) {
                field.visible = false;
            }

            if (field.name === '__slot:scoring' && !canSeeAddressScoring) {
                field.visible = false;
            }
        });

        this.$refs.vuetable.normalizeFields();
    },

    methods: {
        async loadData() {
            try {
                const { data } = await getCustomerAddresses(this.customerNo, { excludeDoors: true });

                this.onDataLoaded(data);
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        onDataLoaded(data) {
            this.data = data;

            this.$emit('addressesLoaded', this.data);
            this.selectRowWithDefaultAddress();
            this.onFilterSet();
            this.cacheData(this.data);
        },

        cacheData(data) {
            try {
                if (this.cacheKey && Array.isArray(data)) {
                    sessionStorage.setItem(this.cacheKey, JSON.stringify(data));
                }
            } catch (e) {
                sessionStorage.removeItem(this.cacheKey);
            }
        },

        filterFunc(parts, row) {
            return fuzzyCompareFunc.evaluate(row, parts);
        },

        selectRow(row) {
            this.selectedRow = row;

            this.$emit('addressSelected', row);
        },

        selectRowWithDefaultAddress() {
            const matchingRow = this.data.find(({ isDefaultDeliveryAddress }) => Boolean(isDefaultDeliveryAddress));

            if (matchingRow) {
                this.defaultDeliveryAddressNo = matchingRow.no;

                this.selectRow(matchingRow);
            }
        },

        onRowClass(dataItem) {
            return dataItem === this.selectedRow ? 'selected-row' : '';
        },

        onShowScoring(row) {
            this.selectedAddressForSdpPopup = row;

            this.toggleSdpPopup();
        },

        toggleSdpPopup() {
            this.showSdpPopup = !this.showSdpPopup;
        },

        async markAsDefaultShippingAddress(row) {
            try {
                const addressNo = this.defaultDeliveryAddressNo !== row.no ? row.no : null;

                await markAsDefaultDeliveryAddress(this.customerNo, { addressNo });

                this.defaultDeliveryAddressNo = addressNo;

                this.selectRow(row);
            } catch (error) {
                bugsnag.notify(error);
            }
        },
    },
};
</script>
