import { bugsnag } from '@/libs/bugsnag.js';
import { getBasket, getCurrentBasket, getCurrentBasketMenuFilters } from '@/modules/baskets/baskets.api.js';
import { getOrderJourneys } from '@/modules/customer/customer.api.js';
import { logOut, verifyAccessToken } from '@/modules/unauthenticated/login/login.api.js';
import router from '@/router/router.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { parseJsonFromLocalStorage, toSentenceCase } from '@/utils/generic.js';
import VueRouter from 'vue-router';

/**
 * Catch an error in `meta.authorized()` and return `true` to redirect to unauthorized route
 * @param {Object} meta
 * @returns {boolean}
 */
const isUnauthorized = meta => {
    try {
        return meta.hasOwnProperty('authorized') && !meta.authorized();
    } catch (error) {
        return true;
    }
};

async function fetchOrderJourneys(customerNo) {
    const isCustomer = store.getters.getUserType === Const.ACCOUNT_TYPE_CUSTOMER;

    try {
        const { data: availableOrderJourneys } = await getOrderJourneys(customerNo);

        store.commit('setAvailableOrderJourneys', availableOrderJourneys);

        if (isCustomer) {
            await fetchCurrentBasketMenuFilters(availableOrderJourneys);
        }
    } catch (error) {
        bugsnag.notify(error);
    }
}

async function fetchCurrentBasket({ isSalesRep, isBasketContext, qualifiesAsCustomizedOrder, to }) {
    try {
        let data;

        if (isSalesRep) {
            data = await getBasket(to.params.basketNo);

            await fetchOrderJourneys(data.data.customerNo);
        } else {
            data = await (isBasketContext ? getBasket(to.params.basketNo) : getCurrentBasket({ customized: qualifiesAsCustomizedOrder || null }));
        }

        await store.dispatch('updateBasket', data.data);
    } catch (error) {
        if (error.response?.status !== Const.HTTP_STATUS.NOT_FOUND) {
            bugsnag.notify(error);
        }
    }
}

async function fetchCurrentBasketMenuFilters(availableOrderJourneys) {
    try {
        for (const orderJourney of availableOrderJourneys) {
            const { data: { data: filters } } = await getCurrentBasketMenuFilters({ orderJourney });

            store.commit('updateProductFilters', {
                filters,
                orderJourney,
            });
        }
    } catch (error) {
        bugsnag.notify(error);
    }
}

/**
 * Redirects to a relevant landing page depending on logged-in user role
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
async function redirectMiddleware(to, from, next) {
    if (to.path === '/' && store.getters['globalStore/authenticated']) {
        const userRole = store.getters.getUserType;
        const rolePathMap = {
            [Const.ACCOUNT_TYPE_CUSTOMER]: '/dashboard',
            [Const.ACCOUNT_TYPE_SALES_REP]: '/customers',
            [Const.ACCOUNT_TYPE_ADMIN]: '/customers',
        };

        // `query.redirectTo` is set when user is not authenticated and attempting to navigate to a route requiring authentication
        const redirectTo = from.query.redirectTo ?? rolePathMap[userRole];

        // Reload the page when changing subsidiary on the same route, to make new API requests to the backend
        if (to.query.subsidiary && redirectTo === from.path) {
            await router.go(0);
        }

        return redirectTo ? next(redirectTo) : next();
    }

    next();
}

/**
 * Redirects to 403 page when a user is not authorized to access `to` route
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
function authorizationMiddleware(to, from, next) {
    if (to.matched.some(({ meta }) => isUnauthorized(meta))) {
        return next({ name: 'Unauthorized', params: [to.path], query: to.query, replace: true });
    }

    next();
}

/**
 * Fetches available order journeys for customer
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @returns {Promise<void>}
 */
async function orderJourneyMiddleware(to, from, next) {
    const isSalesRep = store.getters.getUserType === Const.ACCOUNT_TYPE_SALES_REP;
    const isCustomer = store.getters.getUserType === Const.ACCOUNT_TYPE_CUSTOMER;
    const isCustomerContext = Boolean(to.params.customerNo);
    const customerNo = isCustomer ? store.getters.getRealCustomerNo : to.params.customerNo;
    const shouldFetchOrderJourneys = store.getters.availableOrderJourneys.length === 0 && (isCustomer || (isSalesRep && isCustomerContext));

    if (shouldFetchOrderJourneys) {
        await fetchOrderJourneys(customerNo);
    }

    next();
}

/**
 * Fetches basket for customer or internal user (sales rep, etc.) before continuing navigation to `to` route:
 *  - current basket for customer when not in any basket context
 *  - basket by number for customer when in basket context (basketNo is in the route params)
 *  - basket by number for internal user when in customer basket context (basketNo is in the route params)
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @returns {Promise<void>}
 */
async function currentBasketMiddleware(to, from, next) {
    const qualifiesAsCustomizedOrder = to.path.startsWith(Const.PRODUCT_CUSTOMIZATION_BASE_PATH) || Boolean(to.query.isCustomizedOrder);
    const currentBasket = qualifiesAsCustomizedOrder ? store.getters.customizedBasket : store.getters.getCurrentBasket;
    const isSalesRep = store.getters.getUserType === Const.ACCOUNT_TYPE_SALES_REP;
    const isCustomer = store.getters.getUserType === Const.ACCOUNT_TYPE_CUSTOMER;
    const isBasketContext = Boolean(to.params.basketNo) && to.name !== 'offerHistory';
    const shouldFetchCurrentBasketForCustomer = isCustomer && !currentBasket;
    const shouldFetchCustomerBasketForSalesRep = isSalesRep && !currentBasket && isBasketContext;

    if (shouldFetchCurrentBasketForCustomer || shouldFetchCustomerBasketForSalesRep) {
        await fetchCurrentBasket({
            isSalesRep,
            isBasketContext,
            qualifiesAsCustomizedOrder,
            to,
        });
    }

    next();
}

/**
 * Sets title in HTML head using route's name if available or falls back to N/A
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
function pageTitleMiddleware(to, from, next) {
    document.title = to.name ? `B2B ASICS - ${to.name ? toSentenceCase(to.name) : 'N/A'}` : 'B2B ASICS';

    next();
}

/**
 * Redirects to:
 *  - home page if already authenticated
 *  - login page if not authenticated and trying to access a route requiring authentication
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
async function authenticationMiddleware(to, from, next) {
    const authenticated = localStorage.getItem('ASICS_B2B.authenticated') === 'true';

    // This if statement should only be true when PDF service (Gotenberg) calls the frontend
    if (to.query.accessToken && to.query.subsidiary) {
        try {
            const { data } = await verifyAccessToken({ subsidiary: to.query.subsidiary });

            store.commit('globalStore/setSubsidiary', data.subsidiary);

            await store.dispatch('globalStore/setUpAuthenticatedState');

            return next();
        } catch (error) {
            bugsnag.notify(error);
        }
    }

    if (to.matched.some(({ meta }) => meta.auth) && !authenticated) {
        const query = to.path === '/' ? { ...to.query } : { ...to.query, redirectTo: to.fullPath };

        return next({ path: '/authentication/login', query });
    }

    // Log the user out if already logged in and navigating to unauthenticated route
    if (to.matched.some(({ name }) => name === 'unauthenticated') && authenticated) {
        try {
            await logOut();
            await store.dispatch('globalStore/setUpUnauthenticatedState');
        } catch (error) {
            bugsnag.notify(error);
        }

        return next();
    }

    // START_LOCATION is always true on page reload
    if (from === VueRouter.START_LOCATION || (['login', 'legacyCustomerUpdate'].includes(from.name)) && to.name === 'authenticated') {
        try {
            await (authenticated ? store.dispatch('globalStore/setUpAuthenticatedState') : store.dispatch('globalStore/setUpUnauthenticatedState'));
        } catch (error) {
            if (error.response?.status === Const.HTTP_STATUS.UNAUTHENTICATED) {
                return next('/authentication/login');
            }
        }
    }

    next();
}

/**
 * Sets subsidiary from URL query param `subsidiary`
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
async function subsidiaryMiddleware(to, from, next) {
    const authenticated = localStorage.getItem('ASICS_B2B.authenticated') === 'true';
    const regions = store.getters['globalStore/regions'];
    const subsidiary = parseJsonFromLocalStorage('ASICS_B2B.subsidiary');

    if (!regions.length) {
        try {
            await store.dispatch('globalStore/fetchRegions');
        } catch (error) {
            bugsnag.notify(error);
        }
    }

    // In case we have accessToken, there's no subsidiary in the URL or current subsidiary is the same, run authentication middleware first
    if (to.query.accessToken || !to.query.subsidiary || (to.query.subsidiary === subsidiary?.key && (to.query.countryCode ? to.query.countryCode === subsidiary.countryCode : true))) {
        return next();
    }

    const matchingSubsidiary = store.getters['globalStore/regions']
        .flatMap(({ subsidiaries }) => subsidiaries)
        .find(({ key, countryCode }) => key === to.query.subsidiary && (to.query.countryCode ? to.query.countryCode === countryCode : true));

    if (!matchingSubsidiary) {
        try {
            await logOut();
            await store.dispatch('globalStore/setUpUnauthenticatedState');

            next({ path: '/authentication/login', query: to.query });
        } catch (error) {
            if (error.response?.status === Const.HTTP_STATUS.UNAUTHENTICATED) {
                next('/authentication/login');
            }
        }

        return;
    }

    store.commit('globalStore/setSubsidiary', matchingSubsidiary);

    try {
        await store.dispatch(authenticated ? 'globalStore/setUpAuthenticatedState' : 'globalStore/setUpUnauthenticatedState');

        return authenticated ? next({ name: 'authenticated', query: to.query }) : next();
    } catch (error) {
        if (error.response?.status === Const.HTTP_STATUS.UNAUTHENTICATED) {
            next({ path: '/authentication/login', query: to.query });
        }
    }
}

export {
    subsidiaryMiddleware,
    authenticationMiddleware,
    redirectMiddleware,
    authorizationMiddleware,
    orderJourneyMiddleware,
    currentBasketMiddleware,
    pageTitleMiddleware,
};
