<template>
    <ModalDialog
        size="small"
        :closable="true"
        @close="close"
    >
        <template #header-title>
            <span class="flex items-center gap-4 text-red-dark">
                <i class="fa fa-triangle-exclamation fa-lg" />
                <span v-html="dialogTitle" />
            </span>
        </template>

        <template #body>
            <slot name="body">
                <div v-html="dialogMessage" />
            </slot>
        </template>

        <div class="action-buttons">
            <Button
                :text="$t('general.close')"
                @click.native.stop="close"
            />
        </div>
    </ModalDialog>
</template>

<script>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';

export default {
    name: 'ErrorDialog',

    components: {
        Button,
        ModalDialog,
    },

    props: {
        message: {
            type: String,
            default: null,
        },

        title: {
            type: String,
            default: null,
        },
    },

    computed: {
        dialogTitle() {
            return this.title || this.$t('general.titleError');
        },
        dialogMessage() {
            return this.message || this.$t('general.error');
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
