<template>
    <div>
        <ButtonPlain @click.native="openSubsidiarySelectionDialog">
            <span class="text-xs !uppercase italic leading-snug">{{ subsidiary }}</span>
        </ButtonPlain>
        <SubsidiarySelectionDialog
            v-if="state.subsidiarySelectionDialogVisible"
            :regions="regions"
            @close="changeSubsidiary"
        />
    </div>
</template>
<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import SubsidiarySelectionDialog from '@/modules/unauthenticated/partials/SubsidiarySelectionDialog.vue';
import router from '@/router/router.js';
import { computed, reactive } from 'vue';
import store from '@/store/store.js';

const state = reactive({
    subsidiarySelectionDialogVisible: false,
});

const regions = computed(() => store.getters['globalStore/regions']);
const subsidiary = computed(() => store.state.subsidiary.id);

const openSubsidiarySelectionDialog = () => {
    state.subsidiarySelectionDialogVisible = true;
};

const changeSubsidiary = async subsidiary => {
    state.subsidiarySelectionDialogVisible = false;

    if (!subsidiary) {
        return;
    }

    try {
        await router.push({
            query: {
                subsidiary: subsidiary.key,
                countryCode: subsidiary.countryCode,
            },
        });
    } catch (error) {
        bugsnag.notify(error);
    }
};

</script>
